import React from "react";
import ReactDOM from "react-dom/client";
import { ParallaxProvider } from "react-scroll-parallax";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import App from "./App";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
// import Share from "./pages/Share";
// import Reveal from "./pages/Reveal";
// import OpenBox from "./pages/OpenBox";
// import Spirit from "./pages/Spirit";
// import SpiritPreview from "./pages/SpiritPreview";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <Web3ContextProvider> */}
      <ChakraProvider>
        <ParallaxProvider>
          <Router>
            <Switch>
              <Route path="/" exact component={App} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              {/* <Route path="/share" component={Share} /> */}
              {/* <Route path="/spirit" component={Spirit} /> */}
              {/* <Route path="/reveal" component={Reveal} /> */}
              {/* <Route path="/open-box" component={OpenBox} /> */}
            </Switch>
          </Router>
        </ParallaxProvider>
      </ChakraProvider>
    {/* </Web3ContextProvider> */}
    {/* <ToastContainer closeOnClick={false} position="bottom-right" /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
