import ImgPartnership from "../../assets/images/partnership-img.svg";
import "./style.scss";

const Partner = () => {
  return (
    <div className={"partner-section"}>
      <div className="container">
        <div className="hero-content">
          <div className="title">Partnership</div>
          <img src={ImgPartnership} className="ido-img" />
        </div>
      </div>
    </div>
  );
};

export default Partner;
