// import vector from "../../assets/images/Vector.png";
// import GoMetaIcon from "../../assets/images/go-metaoasis.svg";
// import { socialLinks } from "../../config";
import {Box} from '@chakra-ui/react'
import ImgDragon from "../../assets/images/dragon.png";
import "./style.scss";
import { gameLink } from '../../config';

const Create = () => {
  return (
    <div className={"create-container"}>
      <div className="container">
        <div>
          <p className={"create-container-text"}>Who creates</p>
          <p className={"create-container-header"}>BitDragon?</p>
          <p className={"create-container-body"}>
            We're a passionate gaming team, uniting Bitcoin enthusiasts from
            India and North America, along with The Sandbox's core developers.
            With hit games in alpha3 and alpha4, we've earned strategic
            investment from KuCoin. Our vision? Transform GameFi in the Z era
            with Inscription + NFT assets. <br />
            <br />
            Game on!
          </p>
          <a href={gameLink} target='_blank'>
            <Box mt="32px" rounded={"full"} textAlign={'center'} cursor={"pointer"} w="244px" bg="#fff" fontWeight={"700"} py="15px" fontSize={"16px"} letterSpacing={"-0.36px"} color="#12022f">
                Go to the Sandbox
              </Box>
          </a>
        </div>
      </div>
      <img src={ImgDragon} className="dragon" />
    </div>
  );
};

export default Create;
