import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { useState } from "react";
const list = [
  {
    title: "BitDN",
    img: "/home/tokenomics/0.svg",
  },
  {
    title: "BitWyrm",
    img: "/home/tokenomics/1.svg",
  },
  {
    title: "BitDK",
    img: "/home/tokenomics/2.svg",
  },
  {
    title: "BitPoint",
    img: "/home/tokenomics/3.svg",
  },
];

export default function Tokenomics() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <Flex alignItems={"center"} bgImage="/home/grey-bg.png" minH="100vh">
      <Box w={{ base: "90%", xl: "1200px", "2xl": "1440px" }} mx="auto">
        <Text
          fontWeight={"700"}
          fontSize={"100px"}
          lineHeight={"1.2"}
          letterSpacing={"-1.6px"}
          color="#ffc233"
          mb="40px"
        >
          Tokenomics
        </Text>
        <Flex justify={"space-between"} align={"center"} h="500px">
          <Flex flexDir={"column"} gap="24px">
            {list.map((item, index) => (
              <Box
                color="#767674"
                bg="rgba(0,0,0,.2)"
                border="1px solid rgba(255,255,255,.2)"
                key={index}
                rounded={"62px"}
                fontWeight={"700"}
                fontSize={"36px"}
                lineHeight={"1.2"}
                py="16px"
                textAlign={"center"}
                w={{ base: "309px", "2xl": "400px" }}
                cursor={"pointer"}
                onClick={() => setActiveIndex(index)}
                {...(activeIndex === index && {
                  color: "#fff",
                  border: "1px solid rgba(255,255,255, 1)",
                })}
              >
                {item.title}
              </Box>
            ))}
          </Flex>

          <Image src={list[activeIndex].img} />
        </Flex>
      </Box>
    </Flex>
  );
}
