// import hero_img from "../../assets/images/hero image.svg";
import hero_img_sm from "../../assets/images/hero image sm.svg";
import "./style.scss";

const Hero = () => {
  return (
    <div className={"hero"}>
      <div className="container">
        <div className="hero-content">
          <div className="left">
            <div className="subtitle">What is</div>
            <div className="title">BitDragon?</div>
            <div className="desc">
            BitDragon is not just a game; it's a cross-chain gaming revolution. We've teamed up with The Sandbox and OpenStamp to explore the endless possibilities of BTC inscriptions and NFTs.<br/><br/>
As the first BTC inscription GameFi project based on SRC20 protocol, BitDragon is embarking on an epic gaming journey like never before!
            </div>
          </div>
          {/* <img className="right" src={hero_img_sm} /> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
