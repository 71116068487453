import { Box, Flex, Image, Grid, GridItem, Text } from "@chakra-ui/react";

export default function Spinner() {
  return (
    <Flex alignItems={"center"} bgImage="/home/black-bg.png" minH="100vh">
      <Box w={{ base: "90%", xl: "1200px", "2xl": "1440px" }} mx="auto">
        <Text
          color="#fff"
          fontWeight={"bold"}
          lineHeight={"1.2"}
          letterSpacing={"4px"}
          fontSize={"60px"}
          mb="28px"
        >
          Become a money
          <br /> spinner with{"  "}
          <Text as="span" color="#FFC233">
            BitDragon
          </Text>
        </Text>

        <Flex w="100%" gap={"64px"} align={"center"} justify={"space-between"}>
          <Image src="/home/spinner-text.svg" />
          <Image src="/home/spinner-img.svg" />
        </Flex>
      </Box>
    </Flex>
  );
}
