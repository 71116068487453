import "./style.scss";
import { Grid, GridItem } from "@chakra-ui/react";
import ImgRoadmap1 from "../../assets/images/roadmap-1.svg";
import ImgRoadmap2 from "../../assets/images/roadmap-2.svg";
import ImgRoadmap3 from "../../assets/images/roadmap-3.svg";

const Roadmap = () => {
  return (
    <div className={"roadmap-section"}>
      <div className="container">
        <div className="hero-content">
          <div className="title">Roadmap</div>
          <div className="subtitle">
            Our roadmap can be divided into two stages:
          </div>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={"20px"}
          >
            <GridItem>
              <img src={ImgRoadmap1} />
            </GridItem>
            <GridItem>
              <img src={ImgRoadmap2} />
            </GridItem>
            <GridItem>
              <img src={ImgRoadmap3} />
            </GridItem>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
