import Hero from "./Components/Hero";
import Peek from "./Components/Peek";
import Ecosystem from "./Components/Ecosystem";
import Ido from "./Components/Ido";
// import tiger from "./assets/images/tiger.png";
// import BtnMint from "./assets/images/mint-btn.png";
// import BtnCheckout from "./assets/images/btn-check-out.svg";
// import WhyZzoppers from "./Components/WhyZzoppers";
import WelcomePage from "./pages/Welcome";
import ImgFlower from "./assets/images/flower.svg";
import logo from "./assets/images/logo.svg";
import Roadmap from "./Components/Roadmap";
import Utils from "./Components/Utils";
import Meet from "./Components/Meet";
// import slider2 from "./assets/images/slider 9.png";
// import loaderimg from "./assets/images/loader.png";
import ImgPartners from "./assets/images/banner-partners.svg";
import Create from "./Components/Create";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import { useEffect, useRef, useState } from "react";
import cube from "./assets/images/box.gif";
import "./App.scss";
import Partner from "./Components/Partner";
import Realm from "./pages/Homepage/Realm";
import Spinner from "./pages/Homepage/Spinner";
import Tokenomics from "./pages/Homepage/Tokenomics";

function App() {
  // Refs for scrolls to funcationality
  const heroRef = useRef(null);
  const whyRef = useRef(null);
  const meetRef = useRef(null);
  const detailRef = useRef(null);
  const revealRef = useRef(null);
  const mapRef = useRef(null);
  const joinUsRef = useRef(null);
  const faqRef = useRef(null);
  const whoRef = useRef(null);
  const ecoRef = useRef(null);
  const bitdnRef = useRef(null);

  // variable that control the header close and open funcationality
  const [openHeader, setOpenHeader] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const scroll = (ref) => {
    setOpenHeader(false);
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  // const logKey = (e) => {
  //   if (e.code === "KeyR") {
  //     setRpressed(true);
  //   }
  // };
  // listing for R key press
  // document.addEventListener("keydown", logKey);

  const [rPressed, setRpressed] = useState(true);
  // const [loader, setLoader] = useState(false);

  // useEffect(() => {
  //   // when R is pressed a 2 sec wait is added to show the loading screen, and then when loader is set to true,
  //   // new screen gets loaded
  //   if (rPressed) {
  //     setTimeout(() => {
  //       setLoader(true);
  //     }, 2000);
  //   }
  // }, [rPressed]);

  const enter_clicked = () => {
    // setLoader(false);
    setRpressed(false);
  };

  useEffect(() => {
    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //  setIsMobile(true)
    //  }
    // if (window.screen.availWidth < 1440) {
    //   setIsMobile(true);
    // }
  }, []);

  return (
    <>
      {rPressed ? (
        <WelcomePage enter_clicked={enter_clicked} />
      ) : (
        // when R is pressed this section loads
        // !loader ? (
        //   <div className={"loader-container"}>
        //     <div style={{ margin: "auto" }}>
        //       <img src={loaderimg} style={{ width: "100%" }} />
        //     </div>
        //   </div>
        // ) : (
        // <WelcomePage enter_clicked={enter_clicked} />
        // )
        // this home page loads by default, when R is pressed the above section loads
        <>
          {/* Floating Cude */}
          {/* <img
            src={cube}
            className={"floating-cube"}
            onClick={() => {
              scroll(detailRef);
            }}
          /> */}

          {/* Header */}
          <Header
            openHeader={openHeader}
            setOpenHeader={setOpenHeader}
            scroll={scroll}
            heroRef={heroRef}
            whyRef={whyRef}
            meetRef={meetRef}
            detailRef={detailRef}
            revealRef={revealRef}
            mapRef={mapRef}
            joinUsRef={joinUsRef}
            faqRef={faqRef}
            whoRef={whoRef}
            ecoRef={ecoRef}
            bitdnRef={bitdnRef}
          />
          <div className="header-wadge" />
          {/* <div className="header-badge">
            <img src={ImgFlower} />
            <div>
              Congratulations, BitDragon completed a 1.5 million seed round of financing led by kucoin at a valuation of 15 million.
            </div>
            <img src={ImgFlower} />
          </div> */}

          <div className="mint-banner">
            <img src={logo} className="banner-logo" />
            <div className="title1">
              The First Cross-Chain GameFi Pioneer on SRC20
            </div>
            <div className="banner-partners">
              <img src={ImgPartners} />
            </div>
            {/* <div className="title2">with <span className="title3">100% airdrop</span><br/>just for a better community</div> */}
            {/* <a href="#mint-details" className="btn-mint hover-move">
              <img src={BtnCheckout} />
            </a> */}
          </div>

          {/* <div className="spirit-banner">
            <div className="title1">Test online</div>
            <div className="title2">What is your</div>
            <div className="title2 title3">Spirit Animal in WEB 3.0?</div>
            <a href="/spirit" target="_blank" className="start-btn hover-move">
              Start Test!
            </a>
          </div> */}

          {/* Hero Section */}
          <div ref={heroRef}>
            <Hero />
          </div>

          <Realm />
          <Spinner />
          <Tokenomics />

          {/* <div>
            <Peek />
          </div> */}

          {/* <div ref={ecoRef}>
            <Ecosystem />
          </div> */}
{/* 
          <div ref={bitdnRef}>
            <Ido />
          </div> */}

          {/* Tiger Image*/}
          {/* <video
            src="/ZZOvideo.mp4"
            loop={true}
            muted={true}
            controls={false}
            autoPlay={true}
            style={{ width: "100%", display: "block" }}
          /> */}
          {/* <img src={tiger} style={{ width: "100%", display: 'block' }} /> */}
          {/* Why Zzoppers section */}
          {/* <div ref={whyRef}>
            <WhyZzoppers />
          </div> */}

          {/* Unlock Unlimited Utilities Section */}
          {/* <Utils /> */}

          {/* MEET 2929 Section*/}
          {/* <div ref={meetRef}>
            <Meet />
          </div> */}

          {/* When to Reveal Section */}
          {/* <div ref={revealRef}>
            <Reveal />
          </div> */}

          {/* Roadmap Section*/}
          {/* <div ref={mapRef}>
            <RoadMap />
          </div> */}

          {/* Who creates the Zzoopers? Section*/}
          <div ref={whoRef}>
            <Create />
          </div>

          <div>
            <Partner />
          </div>

          <div>
            <Roadmap />
          </div>

          {/* Join Us Section*/}
          {/* <div ref={joinUsRef}>
            <JoinUs />
          </div> */}

          {/* FAQ */}
          {/* <div ref={faqRef}>
            <Faq />
          </div> */}

          {/* Footer */}
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
