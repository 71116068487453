import { Box, Flex, Image, Grid, GridItem } from "@chakra-ui/react";
import { gameLink } from "../../config";

const list = [
  {
    img: "/home/realm-list/0.png",
    href: gameLink,
  },
  {
    img: "/home/realm-list/1.png",
  },
  {
    img: "/home/realm-list/2.png",
  },
  {
    img: "/home/realm-list/3.png",
  },
  {
    img: "/home/realm-list/4.png",
  },
  {
    img: "/home/realm-list/5.png",
  },
  {
    img: "/home/realm-list/6.png",
  },
];

export default function Realm() {
  return (
    <Flex alignItems={"center"} bgImage="/home/grey-bg.png" minH="100vh">
      <Box w={{ base: "90%", xl: "1200px", "2xl": "1440px" }} mx="auto">
        <Flex gap="60px" w="100%" align={"center"} justify={"space-between"} mb="70px">
          <Image w={{base: "388px", "2xl": "460px"}} src="/home/realm-logo.png" />
          <Image src="/home/realm-text.svg" />
        </Flex>
        <Grid templateColumns="repeat(4, 1fr)" gap="24px">
          {list.map((item, index) => (
            <GridItem as="a" href={item.href ? item.href : null} target="_blank" key={index}>
              <Image src={item.img} />
            </GridItem>
          ))}
        </Grid>
      </Box>
    </Flex>
  );
}
