import { useContext } from "react";
// import { Link } from "react-router-dom";
import { gameLink, socialLinks } from "../../config";
import header_nav from "../../assets/images/header-nav.svg";
import close_header from "../../assets/images/header-close.svg";
import logo from "../../assets/images/logo.svg";
import ImgDragonHi from '../../assets/images/dragon-hi.png'
import IconBook from "../../assets/images/book.svg";
import { useEffect, useState } from "react";
import clsx from "clsx";

const Header = ({
  openHeader,
  setOpenHeader,
  scroll,
  heroRef,
  whyRef,
  meetRef,
  detailRef,
  revealRef,
  whoRef,
  mapRef,
  joinUsRef,
  logoOnly,
  faqRef,
  hideComic,
  ecoRef,
  bitdnRef,
}) => {
  const [displayLinks, setDisplayLinks] = useState(false);

  const header_links = [
    { text: "What is BitDragon?", ref: heroRef },
    { text: "BITDragon  Ecosystem", ref: ecoRef },
    { text: "$BitDN", ref: bitdnRef},
    { text: "Who creates BitDragon?", ref: whoRef },
  ];

  const navs = [
    {
      title: "Staking",
      desc: "COMINGSOON",
    },
    {
      title: "NFT",
      desc: "COMINGSOON",
    },
    {
      title: "Game",
      href: gameLink,
      // children: [
      //   {
      //     title: "Game 1",
      //     href: "",
      //   },
      //   {
      //     title: "Game 2",
      //     href: "",
      //   },
      // ]
    },
    {
      title: "Doc",
      href: "https://bitdragon.gitbook.io/bitdragon-whitepaper",
    },
  ];

  useEffect(() => {
    if (!openHeader) {
      setDisplayLinks(false);
    } else {
      setTimeout(() => {
        setDisplayLinks(true);
      }, 500);
    }
  }, [openHeader]);

  return (
    <>
      <div className={"header"}>
        <div className="container">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              {!openHeader ? (
                <img
                  onClick={() => {
                    setOpenHeader(!openHeader);
                  }}
                  src={header_nav}
                  className={"header-btn"}
                />
              ) : (
                <img
                  onClick={() => {
                    setOpenHeader(!openHeader);
                  }}
                  src={close_header}
                  className={"header-btn"}
                />
              )}
              <a href="/">
                <img src={logo} style={{ marginLeft: "24px", width: 180 }} />
              </a>
            </div>

            {!logoOnly && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                {/* <a
                  href={socialLinks.official}
                  target="_blank"
                  style={{ marginLeft: "auto" }}
                >
                  <button className={"btn metaoasis-header-btn hover-move"}>
                    Back to BitDragon DAO
                  </button>
                </a> */}

                {/* {!hideComic && (
                  <a href={"/comics"} style={{ marginLeft: "auto" }}>
                    <button
                      className={
                        "btn metaoasis-header-btn is-yellow hover-move"
                      }
                    >
                      <img src={IconBook} />
                      Zzo Comic
                    </button>
                  </a>
                )} */}
                {navs.map((item) => (
                  <a href={item.href ? item.href : "#"} style={{ marginLeft: "auto" }} target="_blank">
                    <button className={"btn metaoasis-header-btn"} style={{height: '60px'}}>
                      <div className="title-1">
                        {item.title}
                      </div>
                      {item.desc && <div className="title-2">{item.desc}</div>}
                    </button>
                  </a>
                ))}

                {/* {account ? (
                  <button className={"btn connect-header-btn"}>
                    {account.slice(0, 4)}...{account.slice(-4)}
                  </button>
                ) : (
                  <button
                    className={"btn connect-header-btn hover-move"}
                    onClick={connectWallet}
                  >
                    Connect
                  </button>
                )} */}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "header-body",
          !openHeader ? "header-body-close" : "header-body-open"
        )}
      >
        <div className="container" style={{ height: "100%" }}>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              marginTop: "55px",
            }}
          >
            {displayLinks &&
              header_links.map((link) => {
                return (
                  <p
                    className={"header-link"}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      scroll(link.ref);
                    }}
                  >
                    {link.text}
                  </p>
                );
              })}
          </div>



          <img src={ImgDragonHi} className="img-dragon-hi" />
        </div>
      </div>
    </>
  );
};

export default Header;
